<template>
    <div class="header">
        <div id="spacer" class="md-toolbar" style="position: relative;">
        </div>
        <div id="above-header" class="md-toolbar" style="/*position: relative;*/">
            <!-- grüner balken -->
        </div>
        <md-toolbar class="header_">
            <div class="logo_centered md-toolbar-section-start">
                <router-link to="/">
                    <img id="header_icon" alt="Markenlogo PAW" src="../assets/logo.svg"/>
                </router-link>
            </div>
            <!--<div class="header_name">
                <h3 v-if="authenticated()" class="md-title md-small-hide">
                    <span id="customer_name">
                        {{ $store.state.user.firstName }} {{ $store.state.user.lastName }} ({{ $store.state.user.email }})
                    </span>
                </h3>
            </div>-->
            <div class="header_menu md-toolbar-section-end">
                <md-button v-if="!authenticated()" class="md-button" to="/user/">Nutzer?</md-button>
                <md-button v-if="!authenticated()" class="md-button" to="/partner/">Partner?</md-button>
                <!-- Nutzer -->
                <div v-if="role === 'user'" class="controls md-small-hide">
                    <md-button v-if="!authenticated()" class="md-button md-small-hide" to="/register/user">Registrieren
                    </md-button>
                    <md-button v-if="authenticated()" class="md-button md-small-hide" target="_blank" href="/download/PAW_Abdruck_Anleitung1.1.pdf">Anleitung
                    </md-button>
                    <md-button v-if="authenticated()" class="md-raised md-small-hide" to="/user/dashboard/">Meine PAWs
                    </md-button>
                    <md-button v-if="authenticated()" class="md-raised md-small-hide" to="/user/my_data/">Meine Daten
                    </md-button>
                </div>

                <!-- Partner -->
                <div v-if="role === 'partner'" class="controls md-small-hide">
                    <md-button v-if="!authenticated()" class="md-button md-small-hide" to="/register/partner">Registrieren
                    </md-button>
                    <md-button v-if="authenticated()" class="md-raised md-small-hide" to="/partner/dashboard/">Meine PAWs
                    </md-button>
                    <md-button v-if="authenticated()" class="md-raised md-small-hide" to="/partner/my_data/">Meine Daten
                    </md-button>
                </div>

                <!-- Admin -->
                <div v-if="role === 'admin'" class="controls md-small-hide">
                    <md-button v-if="authenticated()" class="md-raised md-small-hide" to="/admin/dashboard/">Meine Partner
                    </md-button>
                    <!--
                    <md-button v-if="authenticated()" class="md-raised md-small-hide" to="/admin/my_data/">
                        Meine Daten
                    </md-button>
                    -->
                </div>

                <!-- ALLE -->
                <md-button v-if="!authenticated()" class="md-raised md-small-hide" to="" v-on:click="login()">Anmelden
                </md-button>
                <md-button v-if="authenticated()" class="md-raised md-small-hide" to="" v-on:click="logout()">Abmelden
                </md-button>

                <md-menu class="" md-size="auto">
                    <md-button v-show="$vssWidth <= 960" class="md-icon-button md-raised" md-menu-trigger>
                        <md-icon>menu</md-icon>
                    </md-button>

                    <md-menu-content>
                        <!--<md-menu-item v-if="!authenticated() && role === 'partner'" to="/user/">
                            Nutzer?
                        </md-menu-item>
                        <md-menu-item v-if="!authenticated() && role === 'user'" to="/partner/">
                            Partner?
                        </md-menu-item>-->

                        <md-menu-item v-if="authenticated() && role === 'user'" to="/user/dashboard/">
                            Meine PAWS
                        </md-menu-item>
                        <md-menu-item v-if="authenticated() && role === 'partner'" to="/partner/dashboard/">
                            Meine PAWS
                        </md-menu-item>
                        <md-menu-item v-if="authenticated() && role === 'admin'" to="/admin/dashboard/">
                            Meine Partner
                        </md-menu-item>

                        <md-menu-item v-if="authenticated() && role === 'user'" target="_blank" href="/download/PAW_Abdruck_Anleitung1.1.pdf">
                            Anleitung
                        </md-menu-item>

                        <md-menu-item v-if="authenticated() && role === 'user'" to="/user/my_data/">
                            Meine Daten
                        </md-menu-item>
                        <md-menu-item v-if="authenticated() && role === 'partner'" to="/partner/my_data/">
                            Meine Daten
                        </md-menu-item>
                        <!--
                        <md-menu-item v-if="authenticated() && role === 'admin'" to="/admin/my_data/">
                        Meine Daten
                        </md-menu-item>
                        -->

                        <md-menu-item v-if="!authenticated()" to="/register/">Registrieren</md-menu-item>
                        <md-menu-item v-if="!authenticated()" v-on:click="login()">Anmelden</md-menu-item>
                        <md-menu-item v-if="authenticated()" v-on:click="logout()">Abmelden</md-menu-item>
                        <md-menu-item to="/imprint/">Impressum</md-menu-item>
                        <md-menu-item to="/privacypolicy/">Datenschutz</md-menu-item>
                        <md-menu-item to="/faq/">FAQ</md-menu-item>
                        <md-menu-item to="/philosophy/">Philosophie</md-menu-item>
                        <md-menu-item to="/terms/">AGB</md-menu-item>
                    </md-menu-content>
                </md-menu>
                <!--
                <md-button class="md-button" v-on:click="authenticated()">
                    <md-icon>menu</md-icon>
                </md-button>
                -->
                <!-- Sprachauswahl -->
                <!--
                <md-button @click="showLangDial = true" class="md-primary">
                    DE&nbsp;<img alt="Deutsche Flagge" class="lang_flag" src="../assets/languages/de.png">
                </md-button>
                -->
            </div>
        </md-toolbar>

        <LoginDialog v-bind:active="showLoginDial" @onClose="showLoginDial=false" />

        <!-- TODO DUMMY-DIALOG zur Sprachwahl -->
        <md-dialog :md-active.sync="showLangDial">
            <md-dialog-title>Verfügbare Sprachen:</md-dialog-title>
            <md-dialog-content>
                <md-button class="md-primary" @click="showLangDial = false">
                    DE&nbsp;<img alt="Deutsche Flagge" class="lang_flag" src="../assets/languages/de.png">
                </md-button>
                <md-button class="md-primary" @click="showLangDial = false">
                    EN&nbsp;<img alt="Flagge der USA" class="lang_flag" src="../assets/languages/en.png">
                </md-button>
            </md-dialog-content>
        </md-dialog>

        <md-snackbar :md-active.sync="showSnackbarMessage">{{ snackbarMessage }}</md-snackbar>
    </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size';
import All_LoginDialog from "./All_LoginDialog";
const roles = ["admin", "partner", "user"]

export default {
    name: 'All_Header',
    mixins: [VueScreenSize.VueScreenSizeMixin],
    components: {
        LoginDialog: All_LoginDialog
    },
    props: {},
    data: () => ({
        showLangDial: false,
        showLoginDial: false,
        showSnackbarMessage: false,

        showMobileNav: false,

        snackbarMessage: '',
    }),
    computed: {
        role: function () {
            if(this.$store.state.user){
                return roles[this.$store.state.user.role]
            }
            let urlRole = null
            let p = this.$route.path
            p = p.substring(1, p.indexOf("/", 1))
            urlRole = p.length > 2 ? p : null;
            if (urlRole === 'admin' || urlRole === 'partner' || urlRole === 'user') {
                //
            } else {
                urlRole = 'user'
            }
            return urlRole
        },
    },
    mounted() {
    },
    methods: {
        authenticated() {
            return this.$store.state.user !== null
        },
        login() {
            this.showLoginDial = true;
        },
        logout() {
            this.$store.dispatch("logout");
            if (this.role === 'admin') {
                this.$router.push('/admin/')
            } else if (this.role === 'partner') {
                this.$router.push('/partner/')
            } else {
                this.$router.push('/')
            }
        }
    }
}
</script>


<style lang="scss" scoped>
#above-header {
    position: absolute;
    top: 0px;
    background-color: #a6c532;
    padding-bottom: 10px;
    padding-top: 20px;
    height: 70px;
}

#header_icon {
    width: 170px;
    z-index: 5;
    -webkit-filter: drop-shadow(0 0 3px rgba(255, 255, 255, 1));
    filter: drop-shadow(0 0 3px rgba(255, 255, 255, 1));
    /* Similar syntax to box-shadow */
}

#spacer {
    height: 150px;
}

.md-button {
    color: white;
}

.md-icon {
    color: white;
    z-index: 5;
}

.header {
    z-index: 5;
}

.header_ {
    //background-color: #00696d;
    background-color: #8c8000;
}

.md-toolbar {
    height: 70px;
    width: 100%;
    margin-bottom: 10px;
    //position: fixed;
    position: absolute;
    top: 80px;
}

.lang_flag {
    height: 10pt;
}

.controls {
    display: inline-block;
}

.logo_centered {
    padding-top: 30px;
    //padding-left: 10px;
    //display: inline-block;
    height: 100%;
    vertical-align: top;
    //flex-grow: 1;
    text-align: left;
    align-content: start;
    width: 10%;
    z-index: 5;
}

.header_menu {
    width: 40%;
    float: right;
    text-align: right;
    top: 0;
}

.header_name {
    width: 40%;
    float: left;
    text-align: left;
    top: 0;
}

#customer_name {
    color: white;
}

@media print {
    .header {
        display: none;
    }
}
</style>
