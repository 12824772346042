<template>
    <div class="LoginDialog">
        <md-dialog v-bind:md-active.sync="activeForm" :md-close-on-esc="true" @md-opened="openLogin" @md-closed="close" @md-clicked-outside="close">
            <md-dialog-title>Anmeldung:</md-dialog-title>

            <form novalidate @submit.prevent="login">
                <md-dialog-content class="md-layout md-gutter">
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label for="username">E-Mail</label>
                            <md-input id="username" ref="usi" v-model="form.username" :disabled="sending"
                                      autocomplete="username" name="username"/>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label for="password">Passwort</label>
                            <md-input id="password" v-model="form.password" :disabled="sending"
                                      autocomplete="current-password" name="password" type="password"/>
                        </md-field>
                    </div>
                    <div class="md-layout-item size-100">
                            <md-checkbox id="stayLoggedIn" v-model="form.stayLoggedIn"
                                         :disabled="sending" autocomplete="off" name="stayLoggedIn">
                                <label for="stayLoggedIn">Angemeldet bleiben?</label>
                            </md-checkbox>
                    </div>
                </md-dialog-content>

                <md-dialog-actions>
                    <md-button class="md-raised" v-on:click="resetpwd">Passwort vergessen</md-button>
                    <md-button :disabled="sending" class="md-primary md-raised" type="submit">Anmelden</md-button>
                </md-dialog-actions>
            </form>
        </md-dialog>
    </div>
</template>

<script>
const roles = ["admin", "partner", "user"]
export default {
    name: "All_LoginDialog",
    props: {
        active: Boolean
    },
    data: () => ({
        form: {
            username: null,
            password: null,
            stayLoggedIn: false,
        },
        activeForm: false
    }),
    watch: {
        active: function (newVal){
            this.activeForm = newVal
        },
        activeForm: function (newVal){
            if(newVal === false)
                this.close()
        }
    },
    computed: {
        sending: function () {
            return this.$store.state.rxtx > 0
        }
    },
    methods: {
        openLogin() {
            const t = this
            this.$nextTick(() => {
                document.getElementById(t.$refs["usi"].$el.id).focus();
            })
        },
        login() {
            let user = {
                email: this.form.username,
                password: this.form.password,
                stayLoggedIn: this.form.stayLoggedIn
            };
            this.$store.dispatch("logIn", user).then(() => {
                this.$router.push("/" + roles[this.$store.state.user.role] + "/dashboard/")
                this.clearForm();
                this.close()
            });
        },
        resetpwd() {
            this.$router.push("/reset/password/")
            this.close()
        },
        authenticated() {
            return this.$store.state.user !== null
        },
        clearForm() {
            this.form.username = null;
            this.form.password = null;
        },
        close(){
            this.$emit("onClose")
        }
    }
};
</script>
